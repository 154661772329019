<section class="bg-section-wrapper nested-section px-4">
    <div class="bg-section">
    </div>
    <div class="basic-table-layout" *ngIf="true">
        <div class="table-header-custom">
            <div class="row col-12">
                <div class="vrt-middle">
                    <h3 class="m-0 p-0">
                        <b>Add Expense Item</b>
                        <div class="border-bottom-4"></div>
                    </h3>
                </div>
            </div>
        </div>
        <div class="table-header-custom row col-12 overflow-visible">
            <form [formGroup]="expenseItemForm">
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Date<span class="required">*</span></label>
                        <input clrInput type="date" formControlName="date" [max]="checkMaxDate()" class="w-full" />
                        <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                        <clr-control-error *clrIfError="'maxDateInvalid'">Invalid Date (max date allowed is
                            12/31/2200)</clr-control-error>
                    </clr-input-container>
                </div>
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Category<span class="required">*</span></label>
                        <select clrInput formControlName="category" class="w-full">
                            <option [value]="null" disabled>Select</option>
                            <option *ngFor="let category of categoryArray" [ngValue]="category">{{ category }}</option>
                        </select>
                        <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                    </clr-input-container>
                </div>

                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>No. of Attendants<span class="required">*</span></label>
                        <input clrInput type="number" class="text-right w-100 mxw-inh" placeholder="No. of Attendants"
                            formControlName="attendants" min="1" />
                        <clr-control-error *clrIfError="'min'">Value must be positive </clr-control-error>
                    </clr-input-container>
                </div>

                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Amount<span class="required">*</span></label>
                        <input clrInput type="number" min="0.01" class="w-100 text-right mxw-inh" step="0.01"
                            formControlName="amount" (change)="handleAmountChanged()" />
                        <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                        <clr-control-error *clrIfError="'min'">Minimum required amount is 0.01 </clr-control-error>
                        <clr-control-error *clrIfError="'lessValueAllow'">Expense should be less than the distributed
                            fees $ {{project.distributedFees}}
                        </clr-control-error>
                    </clr-input-container>
                </div>

                <div class="row mt-2">
                    <div class="col-12">
                        <label class="clr-control-label">Receipt</label>
                        <input type="file" class="file-input w-100" (change)="fileChange($event)" #fileUpload
                            multiple />
                        <div class="file-upload">
                            <p *ngIf="!fileNames.length && !existingFiles.length" >No file uploaded yet.</p>
                            <div *ngFor="let fileName of existingFiles; let i = index" class="d-flex justify-content-start gap-1 mb-1">
                                <img style="width: 100px" src="{{ fileName.url }}" alt=""
                                    (click)="openPreview(i, existingFiles)" />
                                <div [style.display]="fileName.showModal ? 'block' : 'none'" class="modal"
                                    id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="myModalLabel">Image preview</h4>
                                            </div>
                                            <div class="modal-body">
                                                <img src="{{ fileName.url }}" id="imagepreview"
                                                    style="width: 500px; height: 500px" />
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-default" data-dismiss="modal"
                                                    (click)="closePreview(i, existingFiles)">
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary" (click)="removeUpload(i, existingFiles)"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                            <div *ngFor="let fileName of fileNames; let i = index"
                                class="d-flex justify-content-between mb-1">
                                <p class="sml-lable-heading">{{ fileName.file.name }}</p>
                                <img style="width: 100px" src="{{ fileName.url }}" alt=""
                                    (click)="openPreview(i, fileNames)" />
                                <div [style.display]="fileName.showModal ? 'block' : 'none'" class="modal"
                                    id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                                    aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h4 class="modal-title" id="myModalLabel">Image preview</h4>
                                            </div>
                                            <div class="modal-body">
                                                <img src="{{ fileName.url }}" id="imagepreview"
                                                    style="width: 500px; height: 500px" />
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                                    (click)="closePreview(i, fileNames)">
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary" (click)="removeUpload(i, fileNames)"><i
                                        class="fa fa-trash"></i></button>
                            </div>
                            <div class="d-flex gap-2">
                                <button class="btn btn-primary upload-btn" (click)="fileUpload.click()">
                                    <i class="fa fa-upload"></i>
                                </button>
                                <div class="btn btn-outline borderLess" *ngIf="loadingView">
                                    <clr-spinner></clr-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Vendor</label>
                        <input clrInput type="text" class="w-100 mxw-inh" placeholder="Vendor" formControlName="vendor"
                            min="1" />
                    </clr-input-container>
                </div>

                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Description<span class="required">*</span></label>
                        <input clrInput type="text" class="w-100 mxw-inh" placeholder="Description"
                            formControlName="description" min="1" />
                    </clr-input-container>
                </div>

                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Notes</label>
                        <input clrInput type="text" class="w-100 mxw-inh" placeholder="Notes" formControlName="notes"
                            min="1" />
                    </clr-input-container>
                </div>
            </form>
        </div>

        <!-- Form Buttons -->
        <div class="col-12 p-2 text-right">
            <button class="btn btn-primary" type="button" (click)="closeSidebar()">Cancel</button>
            <button class="btn btn-primary" type="button" [disabled]="expenseItemForm.invalid"
                (click)="addExpenseItem()">Save</button>
        </div>
    </div>
</section>