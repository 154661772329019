import { Injectable, } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  updateClientInfo(input: any) {
    const mutation = `
    
    mutation updateClientInfo($input: CustomerInput) {
      updateClientInfo(input: $input) {
      
          id email title phone cell firstName lastName middleName customerCompanyId
          
        
      }
    }
    `

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input
      }
    });
  }

  bashUrl = '';

  constructor(private http: HttpClient, private router: Router) {
    this.bashUrl = `${environment.baseUrl}/api/employee`;
  }

  getCustomers(filters) {
    let customersListFilters = [];
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          customersListFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, childName: columnFilter.childName, fields: columnFilter.fields, isChild: columnFilter.isChild });
        });
      }
      // Search filter
      if (filters.searchText) {
        customersListFilters.push({ searchText: filters.searchText });
      }
    } else {
      filters = {}
    }

    filters['pageSize'] = filters.pageSize;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'firstName';
    filters['sortOrder'] = filters.sortOrder || 0;
    filters['model'] = filters.model || "";
    filters['fields'] = filters.fields || [];


    const query = `query Query($filters: [Filter], $limit: Int, $offset: Int, $order: [[String]], $fields: [String], $model: String) { 
      customers(filters: $filters, limit: $limit, offset: $offset, order: $order, model: $model, fields: $fields){
        customers {
        id email title phone cell firstName lastName middleName customerCompanyId
          customerCompany {
            id companyName website location clientIdNo taxIdNo dunsNo creditRating zipCode address city state country
          }
        }
        totalCount
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: { filters: customersListFilters, limit: parseFloat(filters.pageSize), offset: filters.offset, model: filters.model, fields: filters.fields, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }
    });
  };

  updateCustomerInfo(body: any) {
    const mutation = `
    mutation saveProjectClient($input: ProjectClientInput) {
      saveProjectClient(input: $input) {
        id projectId clientFileNumber
        customer {
          id email title phone cell firstName lastName middleName customerCompanyId
          customerCompany {
            id companyName website location clientIdNo taxIdNo dunsNo creditRating zipCode address city state country
          }
        }
      }
    }
    `
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        input: body
      }
    });
  }

  searchCustomers(text) {
    let customersListFilters = [{ searchText: text }];
    const query = `query Query($filters: [Filter], $order: [[String]]) { 
      customers(filters: $filters, order: $order){
        customers{
        firstName lastName email title address phone customerCompany{
            companyName
          }
        }
        totalCount
      }
    }`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: { filters: customersListFilters, order: [["firstName", "ASC"]] }
    });
  };

  addClient(input) {
    const mutation = `mutation Mutation($input: CustomerInput) {
      addCustomer(input: $input) {
       id
       email
       title
       firstName
       lastName
       customerCompany {
         id
         companyName
         address
         city
         state
         country
         zipCode
       }
     } 
     }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input }
    });
  }

  getClientByClientId(id) {
    const query = `{
      customer(id: "${id}"){ 
          id email title phone cell firstName lastName middleName address country state city zipcode accountManagerId
            customerCompany {
              id companyName website location clientIdNo taxIdNo dunsNo creditRating zipCode address city state country
            }
      } 
    }`
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getCompanyListByType(type, filters?) {
    let companiesListFilters = [];
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          companiesListFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, childName: columnFilter.childName, fields: columnFilter.fields, isChild: columnFilter.isChild });
        });
      }
      // Search filter
      if (filters.searchText) {
        companiesListFilters.push({ searchText: filters.searchText });
      }

    } else {
      filters = {}
    }
    filters['pageSize'] = filters.pageSize || 10;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'id';
    filters['sortOrder'] = filters.sortOrder || 0;
    filters['model'] = filters.model || "";
    filters['fields'] = filters.fields || [];



    const query = `query Query($type: String, $filters: [Filter], $limit: Int, $offset: Int, $order: [[String]], $fields: [String], $model: String) { 
      getCompanyListByType(type: $type, filters: $filters, limit: $limit, offset: $offset, order: $order, model: $model, fields: $fields){
        id
        type
        company{
          id
        companyName
        address
        city
        state
        country
        zipCode
        website
        location
        dunsNo
        taxIdNo
        creditRating
        clientIdNo
      }   
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`,
      {
        query: query,
        variables: { type: type, filters: companiesListFilters, limit: filters.pageSize, offset: filters.offset, model: filters.model, fields: filters.fields, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }
      })
  }

  getCompaniesByType(type, filters?) {
    let companiesListFilters = [];
    if (type == 'Partner') companiesListFilters.push({type: 'partnerCompaniesOnly'});
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          companiesListFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, childName: columnFilter.childName, fields: columnFilter.fields, isChild: columnFilter.isChild });
        });
      }
      // Search filter
      if (filters.searchText) {
        companiesListFilters.push({ searchText: filters.searchText });
      }

    } else {
      filters = {}
    }
    filters['pageSize'] = filters.pageSize || 10;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'id';
    filters['sortOrder'] = filters.sortOrder || 0;
    filters['model'] = filters.model || "";
    filters['fields'] = filters.fields || [];



    const query = `query Query($filters: [Filter], $limit: Int, $offset: Int, $order: [[String]]) { 
      customerCompanies(filters: $filters, limit: $limit, offset: $offset, order: $order){
        id
        companyName
        address
        city
        state
        country
        zipCode
        website
        location
        dunsNo
        taxIdNo
        creditRating
        clientIdNo
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`,
      {
        query: query,
        variables: { filters: companiesListFilters, limit: filters.pageSize, offset: filters.offset, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }
      })
  }

  getCompaniesByPartner(type, filters?) {
    let companiesListFilters = [];
    if (type == 'Partner') companiesListFilters.push({type: 'partnerCompaniesOnly'});
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          companiesListFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, childName: columnFilter.childName, fields: columnFilter.fields, isChild: columnFilter.isChild });
        });
      }
      // Search filter
      if (filters.searchText) {
        companiesListFilters.push({ searchText: filters.searchText });
      }

    } else {
      filters = {}
    }
    filters['pageSize'] = filters.pageSize || 10;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'companyName';
    filters['sortOrder'] = filters.sortOrder || 0;
    filters['model'] = filters.model || "";
    filters['fields'] = filters.fields || [];



    const query = `query Query($filters: [Filter], $limit: Int, $offset: Int, $order: [[String]]) { 
      partnerListCompanies(filters: $filters, limit: $limit, offset: $offset, order: $order){
        totalCount
        partnerList{
          id
          companyName
          address
          city
          state
          country
          zipCode
          website
          location
          dunsNo
          taxIdNo
          creditRating
          clientIdNo
        } 
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`,
      {
        query: query,
        variables: { filters: companiesListFilters, limit: parseInt(filters.pageSize), offset: filters.offset, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }
      })
  }

  addOrUpdateCompany(input, type) {
    const mutation = `
    mutation Mutation($input: CustomerCompanyInput, $type: String) {
      addOrUpdateCompany(input: $input, type: $type) {
         id
         companyName
       
     } 
     }
    `

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: { input, type }
    });
  }


  getCompanyList() {
    const query = `{
      customerCompanies(order:[["companyName","ASC"]]){
        id
        companyName
        address
        city
        state
        country
        zipCode
        website
        location
        dunsNo
        taxIdNo
        creditRating
        clientIdNo 
      }
    }`;

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getBuisnessTitle() {
    const query = `
{
  getBuisnessTitleList {
    title
  }
}
`


    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }
}
