import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExpenseService } from '../../expense/services/expense.service';
import { ProjectService } from 'src/app/services/project.service';
import { KeycloakService } from 'src/app/services/auth/keycloak.service';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-add-project-expense',
  templateUrl: './add-project-expense.component.html',
  styleUrls: ['./add-project-expense.component.scss']
})
export class AddProjectExpenseComponent {
  @Output() closeSideBox: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() project: any;
  expenseForm: FormGroup;
  expenseId: string;
  maxCalendarDate: any = '2200-12-31';
  staffArray: any = [];
  selectedUser: any = null;
  loggedInUser: any;
  openExpenseItemSidebar: boolean = false;
  expenseItems: any[] = [];
  expenseItem: any = null;

  constructor(
    private formBuilder: FormBuilder,
    private expenseService: ExpenseService,
    private projectService: ProjectService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.generateExpenseId();
  }

  async generateExpenseId() {
    this.expenseService.generateExpenseId().subscribe((res) => {
      if (res?.data?.generateExpenseId) {
        this.expenseId = res.data.generateExpenseId

        this.loggedInUser = KeycloakService.getUserData();

        this.projectService.getStaffByProjectId(this.project?.projectId).subscribe((res) => {
          const staffTracker = new Set();
          for (let staff of res.data.projectStaffs) {
            if (!staffTracker.has(staff.staff.id)) {
              staffTracker.add(staff.staff.id);
              this.staffArray.push(staff.staff);
            }
          }
          this.selectedUser = this.staffArray.find(staff => staff.email === this.loggedInUser.email)
          if (!this.selectedUser) {
            this.selectedUser = {
              id: null,
              name: "Select Assignee",
              email: null
            }
          }
          this.handleResultSelectedStaff({
            value: true,
            data: this.selectedUser
          })
        });

        this.initializeExpenseForm();
      }
    })
  }

  validateDueDate(control: AbstractControl) {
    this.expenseForm?.markAllAsTouched();
    if (this.expenseForm && control.value && this.expenseForm?.get('startDate').value > control.value) {
      return { dueDateInvalid: true };
    }
    return null;
  }

  validateStartDate(control: AbstractControl) {
    if (this.expenseForm && control.value && this.expenseForm?.get('endDate').value < control.value) {
      return { startDateInvalid: true };
    }
    return null;
  }

  syncDatesValidation() {
    this.expenseForm?.get('endDate').setValue(this.expenseForm?.get('endDate').value);
  }

  validateMaxCalenderDate(control: AbstractControl) {
    if (control.value && this.maxCalendarDate < control.value) {
      return { maxDateInvalid: true };
    }
    return null;
  }

  checkMaxDate(date?) {
    if (date) {
      let finalDate = new Date(this.maxCalendarDate) >= new Date(date) ? date : this.maxCalendarDate
      return finalDate;
    }
    return this.maxCalendarDate
  }

  initializeExpenseForm() {
    this.expenseForm = this.formBuilder.group({
      expenseId: [this.expenseId, [Validators.required, Validators.pattern(/^EX\d{5}/m)]],
      startDate: [null, [Validators.required, this.validateStartDate.bind(this), this.validateMaxCalenderDate.bind(this)]],
      endDate: [null, [Validators.required, this.validateDueDate.bind(this), this.validateMaxCalenderDate.bind(this)]],
      status: ["SUBMITTED"],
      approved: [null],
      employeeId: [null, [Validators.required]],
      comments: [""]
    })
  }

  handleResultSelectedStaff(event) {
    if (event?.value && event?.data) {
      this.expenseForm.get('employeeId').setValue(event.data.id);
    }
  }

  editItem(item, index) {
    this.expenseItem = { ...item, index };
    this.toggleExpenseItemSidebar(true);
  }

  deleteItem(index) {
    this.expenseItems.splice(index, 1);
  }

  saveExpense() {
    let data = this.expenseForm.value;

    this.expenseService.addExpenses({ ...data, expenses: 20 }).subscribe((res) => {
      if (res?.data?.addExpense?.id) {
        const expenseId = res.data.addExpense.id
        for (let item of this.expenseItems) {
          item.expenseId = expenseId;
          item.projectId = this.project.projectId;
          item.projectCode = this.project?.projectCode;
        }
        this.expenseService.addExpenseList(this.expenseItems).subscribe((res) => {
          this.alertService.success("Expenses added successfully");
          this.formSubmit.emit({});
        })
      }
      else {
        this.alertService.error("Error adding expenses");
      }
    })

  }

  closeSidebar() {
    this.closeSideBox.emit();
  }

  toggleExpenseItemSidebar(isOpen: boolean) {
    this.openExpenseItemSidebar = isOpen;
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }

  addExpenseItem(event) {
    if (event?.index >= 0) {
      this.deleteItem(event?.index);
      const { index, ...rest } = event;
      this.expenseItems.splice(index, 0, rest);
    }
    else {
      const { index, ...rest } = event;
      this.expenseItems.push(rest);
    }
    this.toggleExpenseItemSidebar(false);
  }
}
