<footer>
    <div class="lower">
        <div class="inner">
            <div class="left">
                <p id="site-copyright">© {{currentYear}} Canyon Data. All rights reserved.</p>

                <nav id="footer-legal-links">
                    <ul id="footer-legal-links-menu" class="menu">
                        <li id="menu-item-4296"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-4296">
                            <a rel="privacy-policy" href="https://canyondata.tech/privacy-policy/" target="_blank"><span class="icon"
                                    style="background-image:url('https://canyondata.tech/wp-content/uploads/2022/04/Shield-Checks_Icon.svg');"></span>Privacy
                                Policy</a>
                        </li>
                        <li id="menu-item-753"
                            class="menu-item menu-item-type-post_type menu-item-object-page menu-item-753">
                            <a href="https://canyondata.tech/terms-conditions/" target="_blank">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</footer>