import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from './pipe/SafePipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { Pagination } from './components/common/pagination/pagination.component';
import { DatatableComponent } from './components/common/datatable/datatable';
import { ColumnFilterComponent } from './components/common/datatable/column-filters/column-filter.component';
import { UserProfileComponent } from './components/common/user-profile/user.profile.component';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { CurrencyDirective, PercentDirective } from './components/common/directives/directives';
import { CurrencyPipe, SafePipe } from './components/common/pipes/pipes';
import { CustomerListComponent } from './components/common/customer-list/customer-list.component';
import { AppMaterialModule } from './material-module';
import { ChipsComponent } from './components/common/chips/chips.component';
import { CompanyListComponent } from './components/common/company-list/company-list.component';
import { CompanyComponent } from './components/common/company/company.component';
import { NgxOtpInputComponent } from 'ngx-otp-input';
import { AddCompanyComponent } from './components/common/add-company/add-company.component';
import { AddClientComponent } from './components/common/add-client/add-client.component';
import { AddProjectExpenseComponent } from './components/common/add-project-expense/add-project-expense.component';
import { StaffListModule } from './components/common/staff-list/staff-list.module';
import { AddExpenseItemComponent } from './components/common/add-expense-item/add-expense-item.component';

@NgModule({
  exports: [
    ChipsComponent,
    UserProfileComponent,
    FormsModule,
    ReactiveFormsModule,
    SanitizeHtmlPipe,
    Pagination,
    DatatableComponent,
    ColumnFilterComponent,
    CurrencyPipe,
    CurrencyDirective,
    PercentDirective,
    CustomerListComponent,
    CompanyListComponent,
    CompanyComponent,
    NgxOtpInputComponent,
    AddCompanyComponent,
    AddClientComponent,
    AddProjectExpenseComponent,
    StaffListModule,
    AddExpenseItemComponent
  ],
  imports: [
    PaginationModule.forRoot(),
    FormsModule,
    CommonModule,
    ClarityModule,
    ReactiveFormsModule,
    AppMaterialModule,
    NgxOtpInputComponent,
    StaffListModule
  ],
  declarations: [
    Pagination,
    ChipsComponent,
    UserProfileComponent,
    DatatableComponent,
    ColumnFilterComponent,
    SanitizeHtmlPipe,
    SafePipe,
    CurrencyPipe,
    CurrencyDirective,
    PercentDirective,
    CustomerListComponent,
    CompanyListComponent,
    CompanyComponent, 
    AddCompanyComponent,
    AddClientComponent,
    AddProjectExpenseComponent,
    AddExpenseItemComponent
  ],
  providers: [CurrencyPipe]
})
export class CommonAppModule { }
