<section class="bg-section-wrapper nested-section px-4">
    <div class="bg-section">
    </div>
    <div class="basic-table-layout" *ngIf="true">
        <div class="table-header-custom">
            <div class="row col-12">
                <div class="vrt-middle">
                    <h3 class="m-0 p-0">
                        <b>Add Expense</b>
                        <div class="border-bottom-4"></div>
                    </h3>
                </div>
            </div>
        </div>
        <ng-container *ngIf="expenseForm">
            <div class="table-header-custom row col-12 overflow-visible">
                <form [formGroup]="expenseForm">
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <clr-input-container class="col-12">
                                <label>Expense Id<span class="required">*</span></label>
                                <input clrInput type="text" value="" placeholder="Expense Id" class="w-full"
                                    formControlName="expenseId" readonly="true" />
                                <clr-control-error *clrIfError="'required'">Required </clr-control-error>
                                <clr-control-error *clrIfError="'pattern'">Please use correct Expense ID format
                                    [EXxxxxx]
                                </clr-control-error>
                            </clr-input-container>
                        </div>
                        <div class="col-md-6">
                            <label class="clr-control-label">Select Staff<span class="required">*</span></label>
                            <div class="user-profile-padding">
                                <app-staff-list [user]="selectedUser" [userList]="staffArray"
                                    (userUpdate)="handleResultSelectedStaff($event)">
                                </app-staff-list>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-6">
                            <clr-input-container class="col-12">
                                <label>Start Date<span class="required">*</span></label>
                                <input clrInput #startDate type="date" class="w-100"
                                    (input)="endDate.min = startDate.value" formControlName="startDate"
                                    [max]="checkMaxDate(endDate.value)" (change)="syncDatesValidation()" />
                                <clr-control-error *clrIfError="'maxDateInvalid'">Invalid Date (max date allowed is
                                    12/31/2200)</clr-control-error>
                            </clr-input-container>
                        </div>
                        <div class="col-md-6">
                            <clr-input-container class="col-12">
                                <label>End Date<span class="required">*</span></label>
                                <input clrInput #endDate type="date" class="w-100" [max]="checkMaxDate()"
                                    formControlName="endDate" />
                                <clr-control-error *clrIfError="'dueDateInvalid'">*End date must be after start
                                    date</clr-control-error>
                                <clr-control-error *clrIfError="'maxDateInvalid'">Invalid Date (max date allowed is
                                    12/31/2200)</clr-control-error>
                            </clr-input-container>
                        </div>
                    </div>
                </form>
            </div>

            <div class="mt-3">
                <div class="table-header-custom">
                    <div class="row col-12">
                        <div class="vrt-middle">
                            <h4 class="m-0 p-0">
                                <b>Expense Items</b>
                                <div class="border-bottom-4"></div>
                            </h4>
                        </div>
                    </div>
                </div>
                <div class="flex text-right mb-2">
                    <button class="btn btn-primary" (click)="toggleExpenseItemSidebar(true)">
                        Add Expense Item
                    </button>
                </div>
                <table class="table table-strip border-table-0 mb-2 icon-hover mt-2">
                    <thead>
                        <tr>
                            <th class="text-center">S.No.</th>
                            <th class="text-center">Date</th>
                            <th class="text-center">Category</th>
                            <th class="text-center">Amount</th>
                            <th class="text-center">Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="expenseItems.length>0">
                            <tr *ngFor="let item of expenseItems; let i = index">
                                <td class="text-center">{{i+1}}</td>
                                <td class="text-center">{{item.date | date:'MM/dd/yyyy'}}</td>
                                <td class="text-center">{{item.category}}</td>
                                <td class="text-center">${{item.amount | number: '1.2-2'}}</td>
                                <td class="text-center">{{item.description}}</td>
                                <td>
                                    <div>
                                        <button class="btn btn-primary-outline add-icon-row ml-2"
                                        type="button" (click)="editItem(item, i)"><i class="fas fa-pen"></i></button>
                                        <button class="btn btn-primary-outline add-icon-row ml-2"
                                            type="button" (click)="deleteItem(i)"><i class="fas fa-trash"></i></button>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!-- Form Buttons -->
            <div class="col-12 p-2 text-right">
                <button class="btn btn-primary" type="button" (click)="closeSidebar()">Cancel</button>
                <button class="btn btn-primary" type="button" [disabled]="expenseForm.invalid"
                    (click)="saveExpense()">Submit</button>
            </div>
        </ng-container>
    </div>
</section>

<!-- Slider Wrapper -->
<div class="slider-wrapper" *ngIf="openExpenseItemSidebar">
    <!-- Backdrop Nested -->
    <div class="backdrop-nested" (click)="toggleExpenseItemSidebar(false)"></div>
    <!-- Slider Container Nested -->
    <div class="slider-container-nested">
        <app-add-expense-item [expenseItem]="expenseItem" [project]="project" (closeSideBox)="toggleExpenseItemSidebar(false)"
            (formSubmit)="addExpenseItem($event)"></app-add-expense-item>
    </div>
</div>