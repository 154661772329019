<section class="bg-section-wrapper">    
    <div class="basic-table-layout" *ngIf="addCompanyForm">
        <div class="table-header-custom" *ngIf="customSubmit === false">
            <div class="row col-12">
                <div class="vrt-middle">
                    <h3 class="m-0 p-0">
                        <b>Add Company</b>
                        <div class="border-bottom-4"></div>
                    </h3>
                </div>
            </div>
        </div>
        <div class="table-header-custom row overflow-visible">
            <form [formGroup]="addCompanyForm">
                <!-- Company Name -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Company Name<span class="required">*</span></label>
                        <input clrInput formControlName="companyName" type="text" value="" placeholder="Company Name"
                            class="w-full" />
                        <clr-control-error *clrIfError="'required'">Company Name Required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- Country -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Country<span class="required">*</span></label>
                        <select clrInput class="w-full" formControlName="country"
                            (change)="onCountryChange($event.target.value)">
                            <option value="" disabled hidden>Select Country</option>
                            <option *ngFor="let country of countryList" [value]="country.name">{{ country.name }}
                            </option>
                        </select>
                        <clr-control-error *clrIfError="'required'">Country is Required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- State -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>State<span class="required">*</span></label>
                        <select clrInput class="w-full" formControlName="state"
                            (change)="onStateChange($event.target.value)">
                            <option value="" disabled hidden>Select State</option>
                            <option *ngFor="let state of stateList" [value]="state.name">{{ state.name }}</option>
                        </select>
                        <clr-control-error *clrIfError="'required'">State is required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- City details -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>City<span class="required">*</span></label>
                        <select clrInput class="w-full" formControlName="city">
                            <option value="" disabled hidden>Select City</option>
                            <option *ngFor="let city of cityList" [value]="city.name">{{ city.name }}</option>
                        </select>
                        <clr-control-error *clrIfError="'required'">City is required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- Address Details -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Address<span class="required">*</span></label>
                        <input clrInput formControlName="address" type="text" value="" placeholder="Address"
                            class="w-full" />
                        <clr-control-error *clrIfError="'required'">Address Required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- Zip Code -->
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Zip Code<span class="required">*</span></label>
                        <input clrInput formControlName="zipCode" type="text" value="" placeholder="Zip Code"
                            class="w-full" />
                        <clr-control-error *clrIfError="'required'">Zip Code Required</clr-control-error>
                    </clr-input-container>
                </div>
            </form>
        </div>
        <!-- Form Buttons -->
        <div class="col-12 p-2 text-right" *ngIf="customSubmit === false">
            <button class="btn btn-primary" type="button" (click)="closeSidebar()">Cancel</button>
            <button class="btn btn-primary" type="submit" [disabled]="addCompanyForm.invalid"
                (click)="saveCompany()">Submit</button>
        </div>
    </div>
</section>