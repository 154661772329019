<section class="bg-section-wrapper nested-section px-4">
    <div class="bg-section">
    </div>
    <div class="basic-table-layout" *ngIf="true">
        <div class="table-header-custom">
            <div class="row col-12">
                <div class="vrt-middle">
                    <h3 class="m-0 p-0">
                        <b>Add Client</b>
                        <div class="border-bottom-4"></div>
                    </h3>
                </div>
            </div>
        </div>
        <div class="table-header-custom row col-12 overflow-visible">
            <form [formGroup]="clientForm">
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>First Name<span class="required">*</span></label>
                        <input clrInput type="text" value="" placeholder="First Name" class="w-full"
                            formControlName="firstName" />
                        <clr-control-error *clrIfError="'required'">First Name is Required</clr-control-error>
                    </clr-input-container>
                </div>
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Last Name<span class="required">*</span></label>
                        <input clrInput type="text" value="" placeholder="Last Name" class="w-full"
                            formControlName="lastName" />
                        <clr-control-error *clrIfError="'required'">Last Name is Required</clr-control-error>
                    </clr-input-container>
                </div>
                <div class="row mt-2">
                    <clr-input-container class="col-12">
                        <label>Email<span class="required">*</span></label>
                        <input clrInput type="email" value="" placeholder="Email" class="w-full"
                            formControlName="email" />
                        <clr-control-error *clrIfError="'required'">Email is Required</clr-control-error>
                    </clr-input-container>
                </div>

                <!-- company details form -->
                <div class="row">
                    <app-add-company [customSubmit]="true"></app-add-company>
                </div>
            </form>
        </div>

        <!-- Form Buttons -->
        <div class="col-12 p-2 text-right">
            <button class="btn btn-primary" type="button" (click)="closeSidebar()">Cancel</button>
            <button class="btn btn-primary" type="button" [disabled]="clientForm.invalid || addCompanyComponent.addCompanyForm.invalid" (click)="saveClient()">Submit</button>
        </div>

    </div>
</section>