import { NgModule } from '@angular/core';
import { StaffListComponent } from './staff-list.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    StaffListComponent,
  ],
  imports: [
      CommonModule,
      FormsModule
  ],
  exports: [
    StaffListComponent,
  ]
})
export class StaffListModule { }