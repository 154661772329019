import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddCompanyComponent } from '../add-company/add-company.component';
import { CustomerService } from '../../customer/services/customer.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent {
  @Output() closeSideBox: EventEmitter<void> = new EventEmitter<void>();
  @Output() createClient: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(AddCompanyComponent) addCompanyComponent: AddCompanyComponent;

  clientForm: FormGroup;
  companyData: any;

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
  ) { }

  ngOnInit() {
    this.initializeClientForm();
  }

  initializeClientForm() {
    this.clientForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
    })
  }


  saveClient() {
    let data = this.clientForm.value;
    const companyData = this.addCompanyComponent.addCompanyForm.value;
    this.customerService.addOrUpdateCompany(companyData, 'Client').subscribe((res) => {
      data.customerCompanyId = res.data.addOrUpdateCompany.id;
      this.customerService.addClient(data).subscribe((res) => {
        const payload = res.data.addCustomer;
        this.createClient.emit(payload);
      })
    })
  }

  closeSidebar() {
    this.closeSideBox.emit();
  }
}
