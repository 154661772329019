import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ChangeDetectorRef, HostListener, ElementRef } from '@angular/core';
import { debounceTime } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-staff-list',
  templateUrl: 'staff-list.component.html'
})
export class StaffListComponent implements AfterViewInit {

  @Input()
  user: {
    photoLink: string;
    name: string;
    email: string;
    title: string;
    badge: string;
  };

  @Input()
  userList: any[];

  @Input()
  parentComponentName: string = '';

  @Output()
  userUpdate = new EventEmitter<Object>();

  @ViewChild('input', { static: false })
  searchText;

  search: any;

  filteredList: any[];

  showlist: boolean = false;
  showData: boolean = true;

  // Get element referance
  @ViewChild('tgArea') tgArea: ElementRef;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    if (this.userList) {
      this.userList.sort(function (st1, st2) {
        if (st1.name > st2.name) return 1;
        if (st1.name < st2.name) return -1;
        return 0;
      });
      this.filteredList = this.userList;
      this.cdr.detectChanges();
    }

    this.searchText?.valueChanges
      .pipe(debounceTime(200))
      .subscribe((model) => this.filterStaffList(model));
  }

  // Defind listener to check clickable area
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if (event.target == this.tgArea?.nativeElement) {
      this.showlist = true;
    } else {
      this.showlist = false;
    }
  }

  // Basic function to toggel list
  showStaffList() {
    this.showlist = !this.showlist;
  }

  // Function to open list and prevent other events
  showStaffListWithEvent(e) {
    this.showlist = !this.showlist;
    e.preventDefault();
    e.stopPropagation();
  }

  handleSelectedStaff(selectedUser) {
    this.user = selectedUser;
    this.userUpdate.emit({ value: true, data: this.user })
    this.showStaffList();
  }

  handleResultSelected(selectedItem) {
    this.user = selectedItem;
    this.userUpdate.emit({ value: true, data: this.user })
    this.showStaffList();
  }

  filterStaffList(text) {
    if (text) {
      const filteredListX = this.userList.filter(us => us.name.toLowerCase().includes(text.toLowerCase()));
      this.filteredList = filteredListX;
    } else {
      this.filteredList = this.userList;
    }
  }

  removeAssignee() {
    this.user = {
      photoLink: null,
      name: 'Select Assignee',
      email: null,
      title: null,
      badge: null,
    }
    this.userUpdate.emit({ value: true, data: { id: null } })
  }
}
