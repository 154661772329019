import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpRequest, HttpBackend
} from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {


  constructor(private http: HttpClient, private httpBackend: HttpBackend) { }

  getUploadUrl(size, folderName) {
    return this.http.get<any>(`${environment.baseUrl}/api/s3Url/${size}`);
  }

  uploadFile(file, fileuploadurl) {
    let tempHTTP: HttpClient = new HttpClient(this.httpBackend);
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data; boundary=--------------------------694695859508113890966023',
    });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
        reportProgress: true, //This is required for track upload process
      });
    return tempHTTP.request(req);
  }

  getImageData(key) {
    return this.http.get<any>(`${environment.baseUrl}/api/s3/objects/${key}`);
  }

  uplaodProfilePicture(file) {
    let formData = new FormData();
    formData.append("image", file);

    return this.http.post<any>(`${environment.baseUrl}/api/s3/profilepic`, formData);
  }

  updateProfilePic(selectedFile: any, currentUrl: any) {
    let formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("currentUrl", currentUrl);
    return this.http.post<any>(`${environment.baseUrl}/api/s3/profilepic`, formData);
  }
}
