<notifier-container></notifier-container>
<!-- Toolbar -->
<ng-container>
  <app-header [showHeader]="header"></app-header>
</ng-container>
<div class="flex-1">
  <router-outlet></router-outlet>
</div>
<!-- Global footer connected found files under components folder under src-->
<app-footer *ngIf="footer"></app-footer>
<clr-modal [(clrModalOpen)]="showProgressModal" [clrModalClosable]="false" [clrModalStaticBackdrop]="false">
  <div class="modal-body text-center">
    <clr-spinner>Loading ...</clr-spinner>
    <h4>Please wait ...</h4>
  </div>
</clr-modal>