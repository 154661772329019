<div class="user-profiler row" *ngIf="user">
    <div class="w-auto p-0">
        <img [src]="user.photoLink || '/assets/images/user.svg'" class="profiller-image" />
    </div>
    <div class="profiler-content w-auto p-0">
        <h5>
            <b>{{ user.name || user.email }}</b>
            <ng-container *ngIf="user.badge"><span class="badge">{{ user.badge }}</span></ng-container>
        </h5>
        <ng-container *ngIf="user.title"
          ><p class="sml-txt">{{ user.title }}</p></ng-container>
        <div class="list-view-btn">
            <span (click)="showStaffListWithEvent($event)" class="list-show-btn"><i class="fas fa-chevron-down"></i></span>
        </div>
    </div>
    <div class="list-view-cnt"  [ngClass]="showlist ? 'show' : ''">
        <div class="list-input">
            <!-- <input type="text" placeholder="Enter name" class="w-100"> -->
            <input #tgArea type="text" autocomplete="off" placeholder="Start typing the name.." class="w-100"
                [(ngModel)]="search" #input="ngModel" id="search" name="search">
            <section class="ta-results list-group" *ngFor="let element of filteredList; let i=index">
                <button *ngIf="i==0 && user.name!='Select Assignee' && this.parentComponentName == 'task'" type="button" class="ta-item list-group-item" (click)="removeAssignee()">
                    <div class="remove-assignee">
                        Remove Assignee
                    </div>
                </button>
                <button type="button" class="ta-item list-group-item" (click)="handleSelectedStaff(element)">
                    <div class="item-name">
                        <img [src]="element.photoLink || '/assets/images/user.svg'" class="profiller-image" />
                        <div class="profiler-content">
                            <h5><b>{{element.name}}</b>
                                <ng-container *ngIf="element.badge"><span class="badge">{{ element.badge }}</span>
                                </ng-container>
                            </h5>
                            <p class="sml-txt">{{ element.title }}</p>
                        </div>
                    </div>
                </button>
            </section>
        </div>
    </div>
</div>