import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { AlertService } from 'src/app/services/alert.service';
import { UploadService } from '../../expense/services/upload.service';

@Component({
  selector: 'app-add-expense-item',
  templateUrl: './add-expense-item.component.html',
  styleUrls: ['./add-expense-item.component.scss']
})
export class AddExpenseItemComponent {
  @Output() closeSideBox: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() project;
  @Input() expenseItem = null;

  fileNames: Array<{ file: File, url: unknown, showModal: Boolean }> = [];
  existingFiles: Array<{ file: String, url: unknown, showModal: Boolean, type: String }> = [];

  expenseItemForm: FormGroup;
  maxCalenderDate: any = '2200-12-31';
  categoryArray: Array<String> = [
    "Certifications",
    "Education",
    "Lodging",
    "Marketing",
    "Mileage",
    "Meals & Tips",
    "Phone",
    "Transportation",
    "Other"
  ];
  loadingView = false;

  constructor(
    private formBuilder: FormBuilder,
    private awsService: UploadService,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.initializeExpenseItemForm(this.expenseItem);
    if (this.expenseItem.receiptLink && this.expenseItem.receiptLink.key && this.expenseItem.receiptLink.key.length) {
      this.initializeExistingFilesArray(this.expenseItem);
    }
  }

  initializeExpenseItemForm(item) {
    this.expenseItemForm = this.formBuilder.group({
      id: [null],
      expenseType: ['Project Expense', [Validators.required]],
      projectCode: [null],
      date: [item?.date || null, [Validators.required, this.validateMaxCalenderDate.bind(this)]],
      category: [item?.category || null, Validators.required],
      attendants: [item?.attendants || 1, Validators.min(1)],
      amount: [item?.amount || 0.01, [Validators.required, Validators.min(0.01)]],
      receiptLink: [item?.receiptLink || null],
      vendor: [item?.vendor || null],
      description: [item?.description || null, Validators.required],
      notes: [item?.notes || null],
      expenseId: [null],
      projectId: [null]
    })

  }

  handleAmountChanged() {
    if (this.expenseItemForm.get('amount').value > this.project.distributedFees) {
      this.expenseItemForm.get('amount').setErrors({ 'lessValueAllow': true });
    } else {
      this.expenseItemForm.get('amount').setErrors(null);
    }
  }

  validateMaxCalenderDate(control: AbstractControl) {
    if (control.value && this.maxCalenderDate < control.value) {
      return { maxDateInvalid: true };
    }
    return null;
  }

  checkMaxDate(date?) {
    if (date) {
      let finalDate = new Date(this.maxCalenderDate) >= new Date(date) ? date : this.maxCalenderDate
      return finalDate;
    }
    return this.maxCalenderDate
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(fileList[0]);
      reader.onload = (_event) => {
        this.fileNames.push({ file: fileList.item(0), url: reader.result, showModal: false })
      }
    }
  }

  openPreview(index, arrayName) {
    arrayName[index].showModal = true;
  }

  closePreview(index, arrayName) {
    arrayName[index].showModal = false;
  }

  removeUpload(index, arrayName) {
    arrayName.splice(index, 1);
  }

  initializeExistingFilesArray(expenseItem) {
    this.existingFiles = [];
    for (let item of expenseItem.receiptLink.key) {
      this.awsService.getImageData(item.name).subscribe(res => {
        if (res.success) {
          this.existingFiles.push({ file: item.name, url: res.success.data, showModal: false, type: item.mimeType })
        }
      })
    }
  }

  addExpenseItem() {
    let body = this.expenseItemForm.value;
    body.index = this.expenseItem?.index >= 0 ? this.expenseItem?.index : -1;

    if (this.fileNames.length || this.existingFiles.length) {
      body.receiptLink = { key: [] };
      for (let i = 0; i < this.existingFiles.length; i += 1) {
        body.receiptLink.key.push({ name: this.existingFiles[i].file, mimeType: this.existingFiles[i].type });
      }

      if (this.fileNames && this.fileNames.length) {
        this.awsService.getUploadUrl(this.fileNames.length, "expenses").subscribe(async res => {
          if (res.success) {
            const data = res.success.data;
            for (let i = 0; i < data.length; i++) {
              this.loadingView = true;
              const fileuploadurl = data[i].urls[0];
              await lastValueFrom(this.awsService.uploadFile(this.fileNames[i].file, fileuploadurl));

              body.receiptLink.key.push({ name: data[i].name, mimeType: this.fileNames[i].file.type });
              this.formSubmit.emit(body);
              this.loadingView = false;
            }
          }
          else this.alertService.error("Unable to upload documents");
        })
      }
      else this.formSubmit.emit(body);

    }
    else {
      this.formSubmit.emit(body);
    }
  }

  closeSidebar() {
    this.closeSideBox.emit();
  }
}
