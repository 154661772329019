import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpRequest, HttpBackend
} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {



  bashUrl = '';

  constructor(private http: HttpClient, private router: Router, private httpBackend: HttpBackend) {
    this.bashUrl = `${environment.baseUrl}/api/graphql`;
  }

  // =====================
  //       QUERY
  // =====================



  getExpenseListByEmployee(employeeId, filters) {

    let expenseFilters = [];
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          if (columnFilter.childName) {
            expenseFilters.push({ type: columnFilter.type, field: columnFilter.childName, filter: columnFilter.filter, value: columnFilter.value, values: columnFilter.values, customType: columnFilter.customType, childName: columnFilter.field });
          }
          else
            expenseFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, values: columnFilter.values, customType: columnFilter.customType });
        });
      }
      // Search filter
      if (filters.searchText) {
        expenseFilters.push({ searchText: filters.searchText });
      }
    } else {
      filters = {}
    }

    filters['pageSize'] = parseInt(filters.pageSize) || 10;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'bidId',
      filters['sortOrder'] = filters.sortOrder || 0
    const query = `query expensesByEmployee($employeeId:ID!, $limit: Int, $offset: Int, $order: [[String]], $filters: [Filter]) {
      expensesByEmployee (employeeId: $employeeId, limit: $limit, offset: $offset, order: $order, filters: $filters) {
        totalCount
        projectEmployeeExpenses {
        id
        expenseId
        expenses
        startDate
        endDate
        status
        approved
        comments
        paymentId
        paymentDeliveryDate
        staff {
          id
          name
          email
          title
          photoLink
        }
      }}
    }`;
    // project {
    //   id
    //   name
    //   projectId
    // }  
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: { employeeId: employeeId, filters: expenseFilters, limit: filters.pageSize, offset: filters.offset, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }

    });
  }

  getExpenseList(filters) {
    let expenseFilters = [];
    if (filters) {
      // Filters
      if (filters.columnFilters && filters.columnFilters.length > 0) {
        filters.columnFilters.forEach(columnFilter => {
          if (columnFilter.childName) {
            expenseFilters.push({ type: columnFilter.type, field: columnFilter.childName, filter: columnFilter.filter, value: columnFilter.value, values: columnFilter.values, customType: columnFilter.customType, childName: columnFilter.field });
          }
          else
            expenseFilters.push({ type: columnFilter.type, field: columnFilter.field, filter: columnFilter.filter, value: columnFilter.value, values: columnFilter.values, customType: columnFilter.customType });
        });
      }
      // Search filter
      if (filters.searchText) {
        expenseFilters.push({ searchText: filters.searchText });
      }
    } else {
      filters = {}
    }

    filters['pageSize'] = parseInt(filters.pageSize) || 10;
    filters['offset'] = filters.pageIndex ? ((filters.pageIndex - 1) * filters.pageSize) : filters.offset || 0;
    filters['sortBy'] = filters.sortBy || 'bidId',
      filters['sortOrder'] = filters.sortOrder || 0


    const query = `query expenses($limit: Int, $offset: Int, $order: [[String]], $filters: [Filter]) {
      expenses(limit: $limit, offset: $offset, order: $order, filters: $filters) {
        totalCount
        projectEmployeeExpenses{
        id
        expenseId
        expenses
        startDate
        endDate
        status
        approved
        comments
        paymentId
        paymentDeliveryDate
        staff {
          id
          name
          email
          title
          photoLink
        }
      }
    }
  }`;
    // project {
    //   id
    //   name
    //   projectId
    // }  
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: { filters: expenseFilters, limit: filters.pageSize, offset: filters.offset, order: [[filters.sortBy, filters.sortOrder == 0 ? "ASC" : "DESC"]] }
    });
  }

  getExpenseById(expenseId) {
    const query = `{expense(id: "${expenseId}") {
      id
      approved
      status
      endDate
      startDate
      expenseId
      expenseType
      paymentId
      paymentDeliveryDate
      staff {
        id
        name
        email
        title
        photoLink
      }
      comments
      expenses
    } 
  } `;
    // project {
    //   id
    //   name
    //   projectId
    // }
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
      variables: {
        expenseId
      }
    });
  }




  getExpenseItemByExpenseId(expenseId) {
    const query = `{expenseItemsByExpenseId(expenseId: "${expenseId}") {
        id
        date
        category
        attendants
        amount
        receiptLink {
          key
        }
        vendor
        description
        notes
        project {
          id
          name
          projectId
          projectFee{
            name
            amount
            type
          }
        }
        expenseType
        expense {
          id
        }
      }}
    `;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  getProjectIdList() {
    const query = `
    {projects (order: [["projectId", "DESC"]]) {
      id
      name
      projectId
    }}`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query
    });
  }

  getProjectByEmployeeId(employeeId) {
    const query = `
    {getProjectByEmployeeId (employeeId: "${employeeId}") {
      id
      name
      projectId
      projectFee{
        amount
        type
      }
    }}`;
    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query
    });
  }

  generateExpenseId() {
    const query = `
    {
      generateExpenseId
    }
    `

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: query,
    });
  }

  // =====================
  //       MUTATION
  // =====================

  addExpenses(body) {
    const mutation = `
    mutation AddExpense($body: ExpenseInput) {
      addExpense(body: $body) {
        id
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        body
      }
    });
  }


  addExpenseList(body) {
    const mutation = `
    mutation Mutation($body: [ExpenseItemInput]) {
      addExpenseItem(body: $body) {
        id
        date
        category
        attendants
        amount
        receiptLink {
          key
        }
        vendor
        description
        notes
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        body
      }
    });
  }


  updateExpenses(body: any) {
    const mutation = `
    mutation Mutation($body: ExpenseInput) {
      updateExpense(body: $body) {
        id
        status
        paymentDeliveryDate
        paymentId
      }
    }`

    return this.http.post<any>(`${environment.baseUrl}/api/graphql`, {
      query: mutation,
      variables: {
        body,
      },
    });
  }
}
