import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { lastValueFrom } from 'rxjs';
import { CustomerService } from '../../customer/services/customer.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss'],
  animations: [
    trigger('slideIn', [
      state('void', style({
        transform: 'translateX(100%)',
      })),
      transition(':enter', [
        animate('0.5s ease-out', style({
          transform: 'translateX(0%)',
        })),
      ]),
      transition(':leave', [
        animate('0.5s ease-in', style({
          transform: 'translateX(100%)',
        })),
      ]),
    ]),
  ],
})
export class AddCompanyComponent {

  @Output() closeSideBox: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() customSubmit: boolean = false;
  addCompanyForm: FormGroup
  countryList: any[] = [];
  stateList: any[] = [];
  cityList: any[] = [];
  selectedCountry: any = null;
  selectedState: any = null;


  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private customerService: CustomerService
  ) { }

  ngOnInit() {
    this.addCompanyForm = this.fb.group({
      companyName: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipCode: ['', Validators.required],
      country: ['', Validators.required]
    });
    this.loadCountries();
  }

  async loadCountries() {
    const response = await lastValueFrom(this.commonService.getCountryList());
    if (response?.data?.getCountryList) {
      this.countryList = response.data.getCountryList;
    }
  }

  async onCountryChange(countryName: string) {
    this.selectedCountry = this.countryList.find(country => country.name === countryName);
    if (this.selectedCountry) {
      const response = await lastValueFrom(this.commonService.getStateListByCountry(this.selectedCountry.isoCode))
      this.stateList = response?.data?.getStateListByCountry || [];
      this.cityList = [];
      this.addCompanyForm.patchValue({ state: '', city: '' });
    }
  }

  async onStateChange(stateName: string) {
    this.selectedState = this.stateList.find(state => state.name === stateName);
    if (this.selectedState) {
      const response = await lastValueFrom(this.commonService.getCityListByState(this.selectedCountry.isoCode, this.selectedState.isoCode));
      this.cityList = response?.data?.getCityListByState || [];
      this.addCompanyForm.patchValue({ city: '' });
    }
  }

  async saveCompany(type = 'Partner') {
    // call the save api, then pass the result
    const response = await lastValueFrom(this.customerService.addOrUpdateCompany(this.addCompanyForm.value, type));
    this.formSubmit.emit(response.data.addOrUpdateCompany);
  }

  closeSidebar() {
    this.closeSideBox.emit();
  }

}
