import { Component, OnInit, Input, EventEmitter, Output, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, HostListener } from '@angular/core';
import { debounceTime } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements AfterViewInit {

  @Input()
  company: {
    companyName: string;
    address: string;
    city: string;
    state: string;
    country: string;
    zipCode: number;
  };

  @Input()
  companyList: any[];

  @Output()
  companyUpdate = new EventEmitter<Object>();

  @Output() companyCreate = new EventEmitter<Object>();

  @Output()
  dropDownTouchedEmitter = new EventEmitter<Object>();

  @ViewChild('input', { static: false })
  searchText;

  @ViewChild('myInput')
  searchTextInput: ElementRef

  search: any;

  openCompanySidebar: boolean = false;

  filteredList: any[];

  showlist: boolean = false;
  showData: boolean = true;

  // Get element referance
  @ViewChild('tgArea') tgArea: ElementRef;
  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {

    if (this.companyList) {
      this.companyList.sort(function (st1, st2) {
        if (st1.name > st2.name) return 1;
        if (st1.name < st2.name) return -1;
        return 0;
      });
      this.filteredList = this.companyList;
      this.cdr.detectChanges();
    }

    this.searchText.valueChanges
      .pipe(debounceTime(200))
      .subscribe((model) => this.filterCompanyList(model));
  }

   // Defind listener to check clickable area
   @HostListener("document:click" , ['$event'])
   clickedOut(event) {
     if(event.target == this.tgArea.nativeElement){
       this.showlist = true;
     }else{
       this.showlist = false;
     }
   }

// Function to open list and prevent other events
showCompanyListWithEvent(e) {
    const previousValue = this.showlist;
    this.showlist = !this.showlist;
    this.handleDropDownTouch(previousValue);
    e.preventDefault();
    e.stopPropagation();
  }

  // Basic function to toggel list
  showCompanyList() {
    const previousValue = this.showlist;
    this.showlist = !this.showlist;
    this.handleDropDownTouch(previousValue);
  }

  handleSelectedCompany(selectedCompany) {
    this.company = selectedCompany;
    this.companyUpdate.emit({ value: true, data: this.company })
    this.showCompanyList();
  }

  handleResultSelected(selectedItem) {
    this.company = selectedItem;
    this.companyUpdate.emit({ value: true, data: this.company })
    this.showCompanyList();
  }

  filterCompanyList(text) {
    if (text) {
      const filteredListX = this.companyList.filter(us => us.companyName.toLowerCase().includes(text.toLowerCase()));
      this.filteredList = filteredListX;
    } else {
      this.filteredList = this.companyList;
    }
  }

  handleDropDownTouch(showlistValue) {
    if (this.dropDownClosed(showlistValue))
      this.dropDownTouchedEmitter.emit({message: "Dropdown Touched"});
  }

  dropDownClosed(showlistValue) {
    return showlistValue && !this.showlist;
  }

  createCompany(event) {
    this.toggleSidebar(false);
    // emit event to populate the current company.
    this.companyCreate.emit(event);
  }

  toggleSidebar(isOpen: boolean) {
    this.openCompanySidebar = isOpen;
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }
}
